<!-- =========================================================================================
	File Name: TheFooter.vue
	Description: Footer component
	Component Name: TheFooter
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <span class="span-footer">
      <span>COPYRIGHT @ {{ currentYear }}</span>
      <span>
        <a
          class="ml-base"
          href="https://technisys.com/"
          target="_blank"
          rel="nofollow"
        >
          Technisys.com
        </a>
        , {{ lang.footer.rights[languageSelected] }}.
      </span>
    </span>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String
    }
  },
  computed: {
    mobile() {
      return window.innerWidth < 576
    },
    ...mapState(['lang', 'languageSelected']),
    currentYear() {
      const today = new Date()
      return today.getFullYear()
    }
  }
}
</script>

<!-- =========================================================================================
	File Name: TheNavbar.vue
	Description: Navbar component
	Component Name: TheNavbar
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <!-- TODO: clean this component -->
  <div class="relative">
    <div class="vx-navbar-wrapper">
      <!-- <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj"> -->
      <header
        id="navBarMobile"
        class="vs-navbar vx-navbar navbar vs-navbar-null"
        style="background: rgb(255, 255, 255);"
      >
        <div class="vs-navbar--header">
          <button @click="openSideBar()" class="vs-navbar--btn-responsive">
            <span class="btn-responsive-line line--1"></span>
            <span class="btn-responsive-line line--2"></span>
            <span class="btn-responsive-line line--3"></span>
          </button>
          <img :src="companyLogo" class="logo-navbar pr-4" />
          <h1 v-if="isNavbarVisible && bot" class="bot-name">
            {{ lang.botMaker.virtualAssistant[languageSelected] }}
            {{ bot.service.name }}
          </h1>

          <!-- active bot version -->
          <vs-chip
            v-if="isNavbarVisible"
            :color="activeVersionStatus"
            class="ml-4"
          >
            {{ activeVersionTag }}
          </vs-chip>

          <!-- versions handler dropdown -->
          <div
            @click="createBotVersion"
            class="cursor-pointer ml-2"
            v-if="isNavbarVisible && bot && activeVersionTag && isAuthAux('konecta.bot_maker.versions_management', true)"
          >
            <vx-tooltip
              :text="
                lang.botMaker.botEditor.navBar.tooltips.fork[languageSelected]
              "
            >
              <feather-icon icon="CopyIcon" svgClasses="w-5 h-5" />
            </vx-tooltip>
          </div>
          <VersionsHandler
            v-if="bot && bot.service && bot.service.id"
            ref="versionsHandler"
            :read-only="isReadOnly"
          />
          <vs-spacer></vs-spacer>
          <NavbarMenu v-if="isNavbarVisible" class="bot-navbar" />
        </div>
        <div class="vs-con-items"></div>
      </header>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { themeConfig, themeColors } from '../../../../themeConfig'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'the-navbar',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    NavbarMenu: () => import('./NavbarMenu.vue'),
    VersionsHandler: () => import('./VersionsHandler.vue')
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: '',
      showFullSearch: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false
    }
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false
    }
  },
  computed: {
    ...mapState([
      'session',
      'maximizeChatContainer',
      'lang',
      'languageSelected',
      'languages',
      'session',
      'useStaffManagement'
    ]),
    ...mapGetters('botMaker', ['bot', 'botVersions']),
    isNavbarVisible() {
      return this.$route.path.includes('/bot-maker/bot/')
    },
    data() {
      return this.$store.state.navbarSearchAndPinList
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    // sidebarWidth() {
    //   return this.$store.state.sidebarWidth;
    // },
    breakpoint() {
      return this.$store.state.breakpoint
    },
    // eslint-disable-next-line vue/return-in-computed-property
    // classObj() {},
    starredPages() {
      return this.$store.getters.starredPages
    },
    starredPagesLimited() {
      return this.starredPages.slice(0, 10)
    },
    starredPagesMore() {
      return this.starredPages.slice(10)
    },
    companyLogo() {
      return (
        this.$store.state.companyLogo ||
        require('@/assets/images/logo/Cyberbank konecta logo.svg')
      )
    },
    activeVersionTag() {
      if (this.botVersions && this.bot) {
        const version = this.botVersions.find(
          v => v._id === this.bot.activeVersion
        )

        return version && version.tag
          ? `${version.tag}`
          : this.lang.botMaker.botEditor.navBar.versions.noTag[
              this.languageSelected
            ]
      }
      return ''
    },
    activeVersionStatus() {
      if (this.botVersions && this.bot) {
        const version = this.botVersions.find(
          v => v._id === this.bot.activeVersion
        )
        return version && version.active ? 'success' : ''
      }
      return ''
    },
    isReadOnly() {
      return !this.isAuthAux('konecta.bot_maker.edit_bot', true)
    }
  },
  methods: {
    ...mapMutations(['UPDATE_LANGUAGE']),
    ...mapActions(['updateTheme', 'updateAndSaveSession']),
    openSideBar() {
      this.$store.state.isSidebarActive = true
    },
    setDefaultTheme() {
      this.updateTheme(themeConfig.theme)
      this.$vs.theme(themeColors)
    },
    logout() {
      this.$router.push('/pages/login')
      this.setDefaultTheme()
      localStorage.removeItem('session')
    },
    toggleStatus() {
      if (this.$store.state.AppActiveUser.status === 'online') {
        this.$store.state.AppActiveUser.status = 'offline'
      } else {
        this.$store.state.AppActiveUser.status = 'online'
      }
    },
    showSidebar() {
      this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true)
    },
    selected(item) {
      this.$router.push(item.url)
      this.showFullSearch = false
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch('updateStarredPage', {
        index: item.index,
        val: !item.highlightAction
      })
    },
    showNavbarSearch() {
      this.showFullSearch = true
    },
    showSearchbar() {
      this.showFullSearch = true
    },
    elapsedTime(startTime) {
      let x = new Date(startTime)
      let now = new Date()
      var timeDiff = now - x
      timeDiff /= 1000

      var seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      var minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      var hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      var days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      var years = timeDiff

      if (years > 0) {
        return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
      } else if (days > 0) {
        return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
      } else if (hours > 0) {
        return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago'
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago'
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? `${seconds} sec ago` : 'just now')
      }

      return 'Just Now'
    },
    test() {
      alert()
    },
    createBotVersion() {
      this.$refs.versionsHandler.open()
    },
    outside: function() {
      this.showBookmarkPagesDropdown = false
    }
  }
}
</script>

<style lang="scss">
.logo-navbar {
  max-width: 150px;
  max-height: 40px;
  margin-right: 20px;
}

#navBarMobile {
  .vs-navbar--header {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 100%;
    .bot-name {
      font-size: 1.1rem;
    }
    .versions {
      display: flex;
      padding: 0 10px;
      overflow-x: auto;
    }
  }

  @media (max-width: 768px) {
    .bot-name,
    .versions {
      display: none;
    }
  }

  @media (max-width: 576px) {
    .bot-name,
    .bot-navbar,
    .versions {
      display: none;
    }
  }
}

.btnSideBar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100%;
}
</style>

<template>
  <!-- sidebar -->
  <div
    class="layout--main"
    :class="[navbarClasses, footerClasses, { 'app-page': isAppPage }]"
  >
    <vx-sidebar
      :sidebarItems="sidebarItems"
      parent=".layout--main"
      :show-sidebar="!maximizeChatContainer && !maximizeLogsStreamContainer"
    />

    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay"></div>

      <div class="content-wrapper">
        <the-navbar
          :navbarColor="navbarColor"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark }
          ]"
        />

        <div class="router-view">
          <div
            class="router-content"
            :class="{ 'mt-0': navbarType === 'hidden' }"
          >
            <div class="content-area__content">
              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import TheNavbar from './components/TheNavbar_base.vue'
import TheFooter from '@/layouts/components/TheFooter.vue'
import version from '../../version'
import konecta from '@/services/konecta'

import { themeConfig, themeColors } from '@/../themeConfig.js'
import { USER_ROLES } from '../../models/Roles/UserRoles'

const { isAuthorized } = require('../../staffManagement/viewPermissionMapping');

export default {
  data() {
    return {
      navbarType: themeConfig.navbarType || 'floating',
      navbarColor: themeConfig.navbarColor || '#fff',
      footerType: themeConfig.footerType || 'static',
      routerTransition: themeConfig.routerTransition || 'none',
      isNavbarDark: false,
      routeTitle: this.$route.meta.pageTitle,
      disableCustomizer: themeConfig.disableCustomizer,
      windowWidth: window.innerWidth, //width of windows
      hideScrollToTop: themeConfig.hideScrollToTop,
      disableThemeTour: themeConfig.disableThemeTour
    }
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark(val) {
      if (this.navbarColor === '#fff' && val) {
        this.updateNavbarColor('#10163a')
      } else {
        this.updateNavbarColor('#fff')
      }
    }
  },
  computed: {
    ...mapState([
      'lang',
      'languageSelected',
      'maximizeChatContainer',
      'maximizeLogsStreamContainer',
      'session',
      'activeChatUser',
      'useStaffManagement'
    ]),
    version() {
      return version.version
    },
    isAppPage() {
      return !!this.$route.path.includes('/apps/')
    },
    isThemeDark() {
      return this.$store.state.theme === 'dark'
    },
    sidebarWidth() {
      return this.$store.state.sidebarWidth
    },
    contentAreaClass() {
      if (this.sidebarWidth === 'default') return 'content-area-default'
      else if (this.sidebarWidth === 'reduced') return 'content-area-reduced'
      else if (this.sidebarWidth) return 'content-area-full'
    },
    sidebarItems() {
      let sidebarItems = []
      const session = JSON.parse(localStorage.session)
      const roles = session && session.roles

      if (this.$store.state.session.useStaffManagement) {
        // contact
        let submenu = [];
        if (isAuthorized({ key: 'konecta.pending_chats.list', permissions: roles })) {
          submenu.push({
            url: '/attention-requests',
            name: this.lang.sidebar.contact.attentionRequests[
              this.languageSelected
            ]
          })
        }
        if (isAuthorized({ key: 'konecta.all_chats.list', permissions: roles })) {
          submenu.push({
            url: '/all-chats',
            name: this.lang.sidebar.contact.allChats[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.pending_chats_settings.view', permissions: roles })) {
          submenu.push({
            url: '/attention-requests-settings',
            name: this.lang.sidebar.contact.attentionRequestsSettings[
              this.languageSelected
            ]
          })
        }
        if (submenu.length > 0) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.contact.title[this.languageSelected],
            slug: 'contact',
            icon: 'MessageCircleIcon',
            submenu
          })
        }

        // metrics
        submenu = [];
        if (isAuthorized({ key: 'konecta.analytics.dashboard', permissions: roles })) {
          submenu.push({
            url: '/dashboard',
            name: this.lang.sidebar.analysis.dashboard[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.analytics.general', permissions: roles })) {
          submenu.push({
            url: '/metrics',
            name: this.lang.sidebar.analysis.metrics[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.analytics.knowledge', permissions: roles })) {
          submenu.push({
            url: '/knowledge',
            name: this.lang.sidebar.analysis.metricsKnowledge[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.analytics.sessions', permissions: roles })) {
          submenu.push({
            url: '/metric-sessions',
            name: this.lang.sidebar.analysis.metricSessions[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.analytics.agents', permissions: roles })) {
          submenu.push({
            url: '/agents',
            name: this.lang.sidebar.analysis.agents[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.analytics.session_flows', permissions: roles })) {
          submenu.push({
            url: '/sessions-flow',
            name: this.lang.sidebar.analysis.sessionsFlow[this.languageSelected]
          })
        }
        if (submenu.length > 0) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.analysis.title[this.languageSelected],
            slug: 'metrics',
            icon: 'TrendingUpIcon',
            submenu
          })
        }

        // surveys
        submenu = [];
        if (isAuthorized({ key: 'konecta.surveys.list', permissions: roles })) {
          submenu.push({
            url: '/surveys',
            name: this.lang.sidebar.surveys.title[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.surveys.results', permissions: roles })) {
          submenu.push({
            url: '/surveys-data',
            name: this.lang.sidebar.surveys.results[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.surveys.metrics', permissions: roles })) {
          submenu.push({
            url: '/surveys-metrics',
            name: this.lang.sidebar.surveys.metrics[this.languageSelected]
          })
        }
        if (submenu.length > 0) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.surveys.title[this.languageSelected],
            slug: 'surveys',
            icon: 'SendIcon',
            submenu
          })
        }

        // diffusions
        submenu = [];
        if (isAuthorized({ key: 'konecta.broadcast.list', permissions: roles })) {
          submenu.push({
            url: '/diffusion',
            name: this.lang.sidebar.diffusion.submenu.audiences.title[
              this.languageSelected
            ]
          })
        }
        if (isAuthorized({ key: 'konecta.broadcast.quick_message', permissions: roles })) {
          submenu.push({
            url: '/diffusion/quick-message',
            name: this.lang.sidebar.diffusion.submenu.sendMessage.title[
              this.languageSelected
            ]
          })
        }
        if (submenu.length > 0) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.diffusion.title[this.languageSelected],
            slug: 'diffusion',
            icon: 'Share2Icon',
            submenu
          })
        }

        // settings
        submenu = [];
        if (isAuthorized({ key: 'konecta.users.list', permissions: roles })) {
          submenu.push({
            url: '/users',
            name: this.lang.sidebar.settings.users[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.roles.list', permissions: roles })) {
          submenu.push({
            url: '/roles',
            // name: this.lang.sidebar.settings.users[this.languageSelected]
            name: 'Roles'
          })
        }
        if (isAuthorized({ key: 'konecta.themes.view', permissions: roles })) {
          submenu.push({
            url: '/themes',
            name: this.lang.sidebar.settings.themes[this.languageSelected]
          })
        }
        if (isAuthorized({ key: 'konecta.nlu_settings.view', permissions: roles })) {
          submenu.push({
            url: '/nlu',
            name: this.lang.sidebar.settings.nlu[this.languageSelected]
          })
        }
        if (submenu.length > 0) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.settings.title[this.languageSelected],
            slug: 'settings',
            icon: 'SlidersIcon',
            submenu
          })
        }

        // bots
        submenu = [];
        if (isAuthorized({ key: 'konecta.bots.list', permissions: roles })) {
          sidebarItems.push({
            url: '/bot-maker/bots',
            name: this.lang.sidebar.bots.title[this.languageSelected],
            slug: 'bots',
            icon: 'BoxIcon'
          })
        }

        // training
        submenu = [];
        if (isAuthorized({ key: 'konecta.clusterization.list', permissions: roles })) {
          submenu.push({
            url: '/clustering',
            name: this.lang.sidebar.training.submenu.clustering.title[
              this.languageSelected
            ]
          })
        }
        if (isAuthorized({ key: 'konecta.trainer.view', permissions: roles })) {
          submenu.push({
            url: '/ai-trainer',
            name: this.lang.sidebar.training.submenu.aiTrainer.title[
              this.languageSelected
            ]
          })
        }
        if (submenu.length > 0) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.training.title[this.languageSelected],
            slug: 'training',
            icon: 'GridIcon',
            submenu
          })
        }

        // audit
        submenu = [];
        if (isAuthorized({ key: 'konecta.audit.list', permissions: roles })) {
          sidebarItems.push({
            id: 'menu-audit',
            url: '/audit',
            name: this.lang.sidebar.audit.title[this.languageSelected],
            slug: 'audit',
            icon: 'BookIcon'
          })
        }

        // security
        submenu = [];
        if (isAuthorized({ key: 'konecta.security.ip_ranges', permissions: roles })) {
          submenu.push({
            url: '/ip-ranges',
            name: this.lang.sidebar.security.ipRanges[this.languageSelected]
          })
        }
        if (submenu.length > 0) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.security.title[this.languageSelected],
            slug: 'security',
            icon: 'ShieldIcon',
            submenu
          })
        }

      } else {
        // contact
        const contactRoles = [
          'company_superadmin',
          'company_supervisor',
          'company_agent'
        ]

        const showContact = roles.some(role => contactRoles.includes(role))

        if (showContact) {
          let submenuContact = [
            {
              url: '/attention-requests',
              name: this.lang.sidebar.contact.attentionRequests[
                this.languageSelected
              ]
            },
            {
              url: '/all-chats',
              name: this.lang.sidebar.contact.allChats[this.languageSelected]
            }
          ]

          if (!this.onlyRole(roles, 'company_agent')) {
            submenuContact.push({
              url: '/attention-requests-settings',
              name: this.lang.sidebar.contact.attentionRequestsSettings[
                this.languageSelected
              ]
            })
          }

          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.contact.title[this.languageSelected],
            slug: 'contact',
            icon: 'MessageCircleIcon',
            submenu: submenuContact
          })
        }

        // metrics
        const metricsRoles = ['company_superadmin', 'company_supervisor']
        const showMetrics = roles.some(role => metricsRoles.includes(role))

        if (showMetrics) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.analysis.title[this.languageSelected],
            slug: 'metrics',
            icon: 'TrendingUpIcon',
            submenu: [
              {
                url: '/dashboard',
                name: this.lang.sidebar.analysis.dashboard[this.languageSelected]
              },
              {
                url: '/metrics',
                name: this.lang.sidebar.analysis.metrics[this.languageSelected]
              },
              {
                url: '/knowledge',
                name: this.lang.sidebar.analysis.metricsKnowledge[
                  this.languageSelected
                ]
              },
              {
                url: '/metric-sessions',
                name: this.lang.sidebar.analysis.metricSessions[
                  this.languageSelected
                ]
              },
              {
                url: '/agents',
                name: this.lang.sidebar.analysis.agents[this.languageSelected]
              },
              {
                url: '/sessions-flow',
                name: this.lang.sidebar.analysis.sessionsFlow[
                  this.languageSelected
                ]
              }
            ]
          })
        }

        // surveys
        const surveysRoles = ['company_superadmin', 'company_supervisor']
        const showSurveys =
          JSON.parse(localStorage.session).surveys &&
          roles.some(role => surveysRoles.includes(role))
        if (showSurveys) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.surveys.title[this.languageSelected],
            slug: 'surveys',
            icon: 'SendIcon',
            submenu: [
              {
                url: '/surveys',
                name: this.lang.sidebar.surveys.title[this.languageSelected]
              },
              {
                url: '/surveys-data',
                name: this.lang.sidebar.surveys.results[this.languageSelected]
              },
              {
                url: '/surveys-metrics',
                name: this.lang.sidebar.surveys.metrics[this.languageSelected]
              }
            ]
          })
        }

        // diffusions
        const audiencesRoles = ['company_superadmin', 'company_supervisor']
        const showAudiences =
          JSON.parse(localStorage.session).audiencesAndPush &&
          roles.some(role => audiencesRoles.includes(role))
        if (showAudiences) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.diffusion.title[this.languageSelected],
            slug: 'diffusion',
            icon: 'Share2Icon',
            submenu: [
              {
                url: '/diffusion',
                name: this.lang.sidebar.diffusion.submenu.audiences.title[
                  this.languageSelected
                ]
              },
              {
                url: '/diffusion/quick-message',
                name: this.lang.sidebar.diffusion.submenu.sendMessage.title[
                  this.languageSelected
                ]
              }
            ]
          })
        }

        // settings
        const settingsRoles = ['company_superadmin', 'company_roles_manager']
        const showSettings = roles.some(role => settingsRoles.includes(role))

        if (showSettings) {
          let submenuSettings = [
            {
              url: '/users',
              name: this.lang.sidebar.settings.users[this.languageSelected]
            }
          ]

          if (this.useStaffManagement) {
            submenuSettings.push({
              url: '/roles',
              // name: this.lang.sidebar.settings.users[this.languageSelected]
              name: 'Roles'
            })
          }

          if (!this.onlyRole(roles, 'company_roles_manager')) {
            submenuSettings.push(
              {
                url: '/themes',
                name: this.lang.sidebar.settings.themes[this.languageSelected]
              },
              {
                url: '/nlu',
                name: this.lang.sidebar.settings.nlu[this.languageSelected]
              }
            )
          }

          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.settings.title[this.languageSelected],
            slug: 'settings',
            icon: 'SlidersIcon',
            submenu: submenuSettings
          })
        }

        // bots
        const botsRoles = [
          'company_superadmin',
          'company_supervisor',
          'company_editor'
        ]
        const showBots = roles.some(role => botsRoles.includes(role))

        if (showBots) {
          sidebarItems.push({
            url: '/bot-maker/bots',
            name: this.lang.sidebar.bots.title[this.languageSelected],
            slug: 'bots',
            icon: 'BoxIcon'
          })
        }

        // training
        const trainingRoles = [
          'company_superadmin',
          'company_supervisor',
          'company_editor'
        ]
        const showTraining =
          JSON.parse(localStorage.session).clustering &&
          roles.some(role => trainingRoles.includes(role))

        if (showTraining) {
          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.training.title[this.languageSelected],
            slug: 'training',
            icon: 'GridIcon',
            submenu: [
              {
                url: '/clustering',
                name: this.lang.sidebar.training.submenu.clustering.title[
                  this.languageSelected
                ]
              },
              {
                url: '/ai-trainer',
                name: this.lang.sidebar.training.submenu.aiTrainer.title[
                  this.languageSelected
                ]
              }
            ]
          })
        }

        // audit
        const showAudit = roles.includes('company_superadmin')
        if (showAudit) {
          sidebarItems.push({
            id: 'menu-audit',
            url: '/audit',
            name: this.lang.sidebar.audit.title[this.languageSelected],
            slug: 'audit',
            icon: 'BookIcon'
          })
        }

        // security
        const securityRoles = ['company_superadmin']
        const showSecurity = roles.some(role => securityRoles.includes(role))

        if (showSecurity) {
          let submenuSecurity = [
            {
              url: '/ip-ranges',
              name: this.lang.sidebar.security.ipRanges[this.languageSelected]
            }
          ]

          sidebarItems.push({
            url: '',
            name: this.lang.sidebar.security.title[this.languageSelected],
            slug: 'security',
            icon: 'ShieldIcon',
            submenu: submenuSecurity
          })
        }

        // create company
        const createCompanyRoles = ['company_sys_admin']
        const showCreateCompany = roles.some(role =>
          createCompanyRoles.includes(role)
        )

        if (showCreateCompany) {
          sidebarItems.push({
            url: '/companies',
            name: this.lang.sidebar.company.title[this.languageSelected],
            slug: 'company',
            icon: 'GlobeIcon'
          })
        }
      }

      //TECKO-990
      /*
      sidebarItems.push({
        url: 'https://help.konecta.global/',
        name: this.lang.sidebar.help.title[this.languageSelected],
        slug: 'external',
        icon: 'HelpCircleIcon',
        target: '_blank'
      })
      */

      // available for all user roles
      sidebarItems.push({
        id: 'menu-login',
        url: '/pages/login',
        name: this.lang.sidebar.logout.title[this.languageSelected],
        slug: 'action',
        action: () => this.alertLogout(),
        icon: 'LogOutIcon'
      })

      return sidebarItems
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating'
      }
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    }
  },
  methods: {
    ...mapActions([
      'updateTheme',
      'updateBotsToSubscribe',
      'updateChatstoSubscribe',
      'updateSocket'
    ]),
    alertLogout() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.sidebar.logout.confirm.title[this.languageSelected],
        text: this.lang.sidebar.logout.confirm.text[this.languageSelected],
        acceptText: this.lang.sidebar.logout.confirm.accept[
          this.languageSelected
        ],
        cancelText: this.lang.sidebar.logout.confirm.cancel[
          this.languageSelected
        ],
        accept: () => this.logout()
      })
    },
    logout() {
      this.$router.push('/pages/login')
      this.setDefaultTheme()
      localStorage.removeItem('session')
    },
    setDefaultTheme() {
      this.updateTheme(themeConfig.theme)
      this.$vs.theme(themeColors)
    },
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    updateNavbar(val) {
      this.navbarType = val
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      this.isNavbarDark = val !== '#fff'
    },
    updateFooter(val) {
      this.footerType = val
    },
    updateRouterTransition(val) {
      this.routerTransition = val
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth
      this.setSidebarWidth()
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)
        this.$store.dispatch('updateSidebarWidth', 'no-sidebar')
      } else {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true)
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val
    },
    async getBots() {
      const roles = this.session && this.session.roles
      let query = ''
      if (
        this.session.user.roles.ids.indexOf(USER_ROLES.AGENT) > -1 &&
        this.session.user.roles.ids.indexOf(USER_ROLES.SUPERVISOR) === -1 &&
        this.session.user.roles.ids.indexOf(USER_ROLES.SUPER_ADMIN) === -1
      ) {
        query = `?as[role]=${USER_ROLES.AGENT}`
      }
      if (
        this.useStaffManagement
        && !isAuthorized({ key: 'konecta.bot_maker.edit_bot_settings', permissions: roles })
      ) {
        query = `?as[role]=${USER_ROLES.AGENT}`
      }
      const response = await konecta.get('/bot' + query)

      const services = response.data.map(element => element.service._id)

      const session = localStorage.getItem('session')
      const token = JSON.parse(session).token
      this.$socket.client.emit('updateControls', {
        token,
        services: services
      })

      this.updateBotsToSubscribe(services)
    },
    async getChats() {
      const endpoint = '/people/all?'
      const paramsQuery = `&where[status]=2&where[user]=${this.session.user
        ._id || this.session.user.id}`

      const response = await konecta.get(endpoint + paramsQuery)
      const chats = JSON.parse(JSON.stringify(response.data.result))

      const aux = chats
        .filter(
          chat =>
            chat.status === 2 &&
            chat.using &&
            chat.using._id === (this.session.user._id || this.session.user.id)
          //  || (this.chatByQuery && this.chatByQuery.index === index)
        )
        .map(chat => ({
          sender: chat.sender,
          service: chat.service
        }))

      // chats seleccionados sin tomar pero visibles en la sidebar
      if (
        this.activeChatUser &&
        aux
          .map(element => `${element.service}${element.sender}`)
          .indexOf(
            `${this.activeChatUser.service}${this.activeChatUser.sender}`
          ) === -1
      ) {
        aux.push({
          sender: this.activeChatUser.sender,
          service: this.activeChatUser.service
        })
      }

      const session = localStorage.getItem('session')
      const token = JSON.parse(session).token
      this.$socket.client.emit('updateSenders', {
        token,
        senders: aux
      })

      // chats.forEach(chat => {
      //   if (chat.status === 2) {
      //     this.$socket.client.emit('join', {
      //       type: 'sender',
      //       service: chat.service,
      //       sender: chat.sender
      //     })
      //   }
      // })

      this.updateChatstoSubscribe(aux)
      return chats
    },
    onlyRole(roles, uniqueRole) {
      return !roles.some(role => role !== uniqueRole)
    }
  },
  created() {
    this.setSidebarWidth()
    if (this.navbarColor === '#fff' && this.isThemeDark) {
      this.updateNavbarColor('#10163a')
    } else {
      this.updateNavbarColor(this.navbarColor)
    }
  },
  async mounted() {
    const session = JSON.parse(localStorage.session)
    const roles = session && session.roles

    if (!this.onlyRole(roles, 'company_sys_admin')) {
      await this.getBots()
      await this.getChats()
      this.updateSocket(this.$socket)
    }
  },
  components: {
    TheNavbar,
    TheFooter
  }
}
</script>

<style lang="scss">
/*Main Style Dialogs*/
div.con-vs-dialog .vs-dialog {
  footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .vs-button {
      margin-left: 0.5rem;
    }
    .vs-button-null {
      background: rgba(var(--vs-danger), 1) !important;
      color: white !important;
      margin-right: 0.75rem;
    }
  }
}
</style>
